<template>
  <div class="mask">
    <div class="maskcenter">
      <div class="maskmesstop" :style="{top: mask.messtop,left:mask.messleft}"></div>
      <div class="maskmess" :style="{top: mask.maskTop,left:mask.maskLeft}">
        
        <div class="close">
          <div @click="maskClick('close')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="title">{{ mask.title }}</div>
        <div class="next" @click="maskClick('next')" v-if="mask.showNext">
          <div>下一步</div>
          <!-- <el-button type="primary">下一步</el-button> -->
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "maskLayer",
  components: {
  },
  props:{
    mask:Object
  },
  data () {
    return {
      // mask:{
      //   title:'若需要下载企业信用报告，请先登录!',
      //   showNext:true,
      //   maskTop:'50px',
      //   maskLeft:'1150px',
      //   messtop: '50px',
      //   messleft: '1350px',
      // }
    };
  },
  created(){
    // console.log(this.mask,'33')
  },
  mounted () {
  
  },
  methods: {
    maskClick(v){
      this.$emit('maskClick',{type:v})
    }
    
  },
};
</script>

<style lang="less" scoped>
body{
  // overflow: hidden;
}
.mask{
  background-color: rgba(0, 0, 0, 0.3);
  // opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  .maskcenter{
    width: 1200px;
    height: 100%;
    text-align: center;
  }
  .maskmess{
    position: relative;
    color: #000;
    background-color: #fff;
    border-radius: 5px;
    width: 300px;
    z-index: 9;
    opacity: 1;
    // background: rgba(0, 0, 240, 1);
    padding: 10px;
    .close{
      color: #000;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      
      div{
        width: 30px;
        cursor: pointer;
        .el-icon-close{
          color: #000;
          font-size: 20px;
        }
      }
    }
    .title{
      margin: 0 0 10px 0;
      text-align: left;
    }
    .next{
      
      color: #000;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      
      div{
        background-color: #409eff;
        color: #fff;
        border: 0.5px solid #409eff;
        width: 50px;
        padding: 2px 6px;
        border-radius: 3px;
      }
    }

    
  }
  .maskmesstop{
      width: 0px;
      height: 0px;
      position: relative;
      
      opacity: 1;
      // border: 20px solid transparent;
      border-top: 20px solid transparent;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 40px solid transparent;
      border-bottom-color: #fff;
    }
}
</style>
