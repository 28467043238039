import CryptoJS from 'crypto-js';

let util = {};

util.AES_KEY = CryptoJS.enc.Latin1.parse('SJUDHKGYHJHDNMCH');
util.AES_IV = CryptoJS.enc.Latin1.parse('HDJNGMKLCHGJKDUG');

util.encrypt = function (data) {
    data = CryptoJS.AES.encrypt(data, util.AES_KEY, {
        iv: util.AES_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    }).toString();
    return data;
};

util.decrypt = function (data) {
    if (!data) {
        return {};
    }

    let decrypted = CryptoJS.AES.decrypt(data, util.AES_KEY, {
        iv: util.AES_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
};


export default util;
